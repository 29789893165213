<template>
  <section>
    <vue-headful :title="title" />
    <v-container>
      <v-row no-gutters>
        <v-col cols="12" lg="6" md="12">
          <h1 class="fontD mt-5 pa-3 text-left">{{ dominio }}</h1>
        </v-col>
        <v-col cols="8" lg="6" md="12">
          <v-btn
            class="ma-2 mt-7 float-lg-right"
            text
            color="#616161"
            dark
            @click="overlay = !overlay"
          >
            <router-link :to="{ name: 'admin-dominios' }">
              <v-icon color="#616161" left>
                mdi-arrow-left
              </v-icon>
              <span class="botonNegro">atras</span>
            </router-link>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-card flat>
        <v-alert border="bottom" colored-border type="info" elevation="2">
          Estimado usuario, por favor agregar al menos tres tipos de contactos y
          es obligatorio agregar dos DNS.
        </v-alert>
        <v-alert
          v-if="msgerror"
          border="right"
          colored-border
          type="error"
          elevation="2"
          transition="scale-transition"
        >
          <a class="black--text" v-html="msgerror"></a>
        </v-alert>

        <v-expansion-panels
          background-color="uniColor"
          align-with-title
          multiple
        >
          <!--    <v-tabs-slider color="yellow"></v-tabs-slider> -->
          <v-expansion-panel>
            <v-expansion-panel-header
              ><h3>Datos de Contacto</h3></v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <template v-if="contactosList">
                <v-expansion-panel>
                  <v-row align="center">
                    <v-col cols="4">
                      <v-select
                        v-model="agregarContacto"
                        dense
                        :menu-props="{ top: true, offsetY: true }"
                        color="blue darken-2"
                        label="Agregar contacto"
                        outlined
                        filled
                        :items="contactosList"
                        item-text="nombre"
                        item-value="nombre"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" lg="2">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon class="mt-n8" v-bind="attrs" v-on="on">
                            <v-icon
                              color="green"
                              v-on:click="addContactoCard(agregarContacto)"
                            >
                              mdi-plus-circle
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Tiene que agrear al menos tres contactos</span>
                      </v-tooltip>
                    </v-col>
                    <!-- <v-col cols="12" lg="2">
                      <v-layout class="justify-start">
                              <div class="mt-n6">
                                 
                                  </div>
                          </v-layout>
                    </v-col> -->
                  </v-row>
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      v-for="(item, i) in cardContactos"
                      :key="'CD' + i"
                    >
                      <v-card elevation="2" outlined>
                        <v-container class="d-flex align-center">
                          <v-layout class="justify-start">
                            <div class="py-0 px-0">
                              <v-card-title>{{ item.name }}</v-card-title>
                            </div>
                          </v-layout>
                          <v-layout class="justify-end">
                            <div class="">
                              <v-btn class="float-right" icon>
                                <v-icon
                                  color="cardActivedanger"
                                  v-on:click="removeContactoCard(item.name)"
                                >
                                  mdi-delete
                                </v-icon>
                              </v-btn>
                            </div>
                          </v-layout>
                        </v-container>
                        <v-divider></v-divider>
                        <v-form>
                          <!--                      INCIO CARD ITEMS-->
                          <v-col
                            cols="12"
                            lg="12"
                            v-for="(itemc, k) in item.contactos"
                            :key="k"
                          >
                            <v-row no-gutters>
                              <v-col cols="12" lg="6">
                                <v-text-field
                                  dense
                                  class="pa-2"
                                  color="blue darken-2"
                                  placeholder="Nombre"
                                  outlined
                                  :rules="[(v) => !!v || 'Campo Obligatorio']"
                                  v-model="itemc.nombre"
                                  filled
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" lg="6">
                                <v-text-field
                                  dense
                                  class="pa-2"
                                  color="blue darken-2"
                                  placeholder="Teléfono1"
                                  outlined
                                  :counter="8"
                                  type="number"
                                  :rules="[
                                    (v) => !!v || 'Campo Obligatorio',
                                    (v) =>
                                      v.length >= 8 ||
                                      'El debe contener al menos 8 digitos ',
                                  ]"
                                  filled
                                  v-model="itemc.telefono"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row no-gutters>
                              <v-col cols="12" lg="6">
                                <v-text-field
                                  dense
                                  class="pa-2"
                                  color="blue darken-2"
                                  placeholder="Celular"
                                  outlined
                                  :counter="8"
                                  type="number"
                                  :rules="[
                                    (v) => !!v || 'Campo Obligatorio',
                                    (v) =>
                                      v.length >= 8 ||
                                      'El debe contener al menos 8 digitos ',
                                  ]"
                                  filled
                                  v-model="itemc.celular"
                                ></v-text-field>
                              </v-col>
                              <v-col
                                cols="12"
                                lg="6"
                                v-for="(input, k) in itemc.correoElectronico"
                                :key="k"
                              >
                                <v-row no-gutters class="">
                                  <v-col cols="12" lg="10">
                                    <v-text-field
                                      dense
                                      class="pa-2"
                                      color="blue darken-2"
                                      outlined
                                      v-model="input.value"
                                      label="Correo"
                                      placeholder="Correo"
                                      :rules="emailRules"
                                      filled
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" lg="2">
                                    <v-btn icon class="mt-2">
                                      <v-icon
                                        color="green"
                                        v-on:click="add(itemc, k, item.nombre)"
                                        v-show="
                                          k ==
                                            itemc.correoElectronico.length - 1
                                        "
                                      >
                                        mdi-plus-circle
                                      </v-icon>
                                    </v-btn>
                                    <v-btn icon class="mt-2">
                                      <v-icon
                                        color="cardActivedanger"
                                        v-on:click="remove(itemc, k)"
                                        v-show="
                                          k ||
                                            (!k &&
                                              itemc.correoElectronico.length >
                                                1)
                                        "
                                      >
                                        mdi-delete
                                      </v-icon>
                                    </v-btn>
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-container class="d-flex align-center">
                                <v-layout class="justify-start">
                                  <div class="py-0 px-0  ">
                                    <v-switch
                                      color="green"
                                      v-model="itemc.recibeNotificacion"
                                      :label="
                                        `El cliente recibira notificaciones`
                                      "
                                    ></v-switch>
                                  </div>
                                </v-layout>
                                <!-- <v-layout class="justify-end">
                                  <div class="">
                                    <v-btn
                                        text
                                        v-on:click="solicitar"
                                    >Guardar
                                    </v-btn>
                                  </div>

                                </v-layout> -->
                              </v-container>
                            </v-row>
                          </v-col>
                          <!--                      FIN CARD ITEMS-->
                        </v-form>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-expansion-panel>
              </template>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header
              ><h3>Configuración de DNS</h3></v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <!--           INICIO TAB CONFUGURACION DNS-->

              <v-row align="center">
                <v-col cols="10" lg="4" md="3">
                  <!-- <v-text-field
                    dense
                    :menu-props="{ top: true, offsetY: true }"
                    color="blue darken-2"
                    label="Agregar DNS"
                    outlined
                    filled
                    disabled
                ></v-text-field> -->
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        v-bind="attrs"
                        v-on="on"
                        class="ma-2"
                        color="success"
                        outlined
                      >
                        <v-icon color="green" v-on:click="addDNSCard()">
                          mdi-plus-circle
                        </v-icon>
                        Agregar DNS
                      </v-chip>
                    </template>
                    <span>Tiene que agregar al menos dos DNS</span>
                  </v-tooltip>
                </v-col>
                <!-- <v-col cols="2" lg="2" md="3">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">       
                    <v-btn icon class="d-flex justify-end  align-center mb-7"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon
                          color="green"
                          v-on:click="addDNSCard()">
                        mdi-plus-circle
                      </v-icon>
                    </v-btn>
                </template>
                <span>Tiene que agregar al menos dos DNS</span>
                </v-tooltip>
              </v-col> -->
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" v-for="(item, i) in cardDNS" :key="'CD' + i">
                  <v-card elevation="2" outlined>
                    <v-container class="d-flex align-center">
                      <v-layout class="justify-start">
                        <div class="py-0 px-0">
                          <v-card-title>{{ item.title }}</v-card-title>
                        </div>
                      </v-layout>
                      <v-layout class="justify-end">
                        <div class="">
                          <v-btn class="float-right" icon>
                            <v-icon
                              color="cardActivedanger"
                              v-on:click="removeDNSCard(item.id)"
                            >
                              mdi-delete
                            </v-icon>
                          </v-btn>
                        </div>
                      </v-layout>
                    </v-container>
                    <v-divider></v-divider>
                    <v-form>
                      <!--                      INCIO CARD ITEMS-->
                      <v-col
                        cols="12"
                        lg="12"
                        v-for="(itemc, k) in item.detalle"
                        :key="k"
                      >
                        <v-row no-gutters>
                          <v-col cols="12" lg="6">
                            <v-text-field
                              dense
                              class="pa-2"
                              color="blue darken-2"
                              label="Host"
                              outlined
                              :rules="hostRule"
                              v-model="itemc.ip"
                              v-on:keypress="isCaseOne(dominio, itemc.ip)"
                              filled
                            >
                            </v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            lg="6"
                            v-for="(input, k) in itemc.direccionesIP"
                            :key="k"
                          >
                            <v-row no-gutters class="">
                              <v-col cols="12" lg="10">
                                <v-text-field
                                  dense
                                  class="pa-2"
                                  color="blue darken-2"
                                  outlined
                                  v-model="input.value"
                                  :disabled="isDisabled"
                                  label="Dirección IP DNS"
                                  :rules="ipDnsRule"
                                  filled
                                ></v-text-field>
                              </v-col>
                              <v-col cols="2" lg="2">
                                <v-btn
                                  icon
                                  class="d-flex justify-end  align-center mb-6"
                                >
                                  <v-icon
                                    color="green"
                                    v-on:click="
                                      pushDireccionIP(itemc, k, input.value)
                                    "
                                    v-show="k == itemc.direccionesIP.length - 1"
                                  >
                                    mdi-plus-circle
                                  </v-icon>
                                </v-btn>
                                <!-- <v-btn icon class="mt-2">
                                <v-icon
                                    color="cardActivedanger"
                                    v-on:click="removeIP(itemc,k)" v-show="k || ( !k && itemc.direccionesIP.length > 1)">
                                  mdi-delete
                                </v-icon>
                              </v-btn> -->
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                        <v-row no-gutters>
                          <v-container class="d-flex align-center">
                            <v-layout class="justify-end">
                              <div class="">
                                <!--  <v-btn
                                  text
                                  v-on:click="solicitar"
                              >Guardar
                              </v-btn> -->
                              </div>
                            </v-layout>
                          </v-container>
                        </v-row>
                      </v-col>

                      <!--                      FIN CARD ITEMS-->
                    </v-form>
                  </v-card>
                </v-col>
              </v-row>
            </v-expansion-panel-content>

            <!--           FIN TAB CONFIGURACION DNS -->
          </v-expansion-panel>
        </v-expansion-panels>

        <v-btn
          class="white--text float-right ma-2"
          depressed
          color="green"
          elevation="2"
          v-on:click="solicitar"
          >Enviar
        </v-btn>
      </v-card>
      <v-overlay :value="overlay">
        <v-progress-circular
          indeterminate
          size="64"
          color="#007BFF"
        ></v-progress-circular>
      </v-overlay>
    </v-container>
  </section>
</template>
<script>
import vueHeadful from "vue-headful";
import Vue from "vue";

export default {
  components: {
    vueHeadful,
  },
  async mounted() {
    this.param = this.$route.params.id;
    (this.dominio = localStorage.getItem("detalledominio")),
      await this.getContactoTipo();
    await this.defaultValueCardContactos();
  },
  data: () => ({
    overlay: false,
    msgerror: "",
    listahost: [
      {
        icon: "mdi-delete",
        text: 121211212,
      },
      {
        icon: "mdi-delete",
        text: 121211212,
      },
      {
        icon: "mdi-delete",
        text: 121211212,
      },
    ],
    param: 0,
    idDetallePedido: 0,
    host: "",
    hostTitle: "",
    ip: "",
    reg: /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/,
    hostReg: /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\\-]*[a-zA-Z0-9])\.){2,}$/,
    ipRegx: /^((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])$/,
    autoRenovacion: true,
    tab: null,
    items: ["Configuración DNS", "Datos de Contacto"],
    items2: [
      { title: "Contacto administrativo" },
      { title: "Contacto técnico" },
      { title: "Contacto de pago del dominio virtual" },
    ],
    dnsItems: [
      {
        cardTitle: "Configuración DNS",
      },
    ],
    ips: [],
    agregarContacto: "",
    tipoContacto1: {
      name: "Contacto Técnico",
    },
    title: "Detalles Dominio",
    dominio: "",
    hostRule: [
      (v) => !!v || "el host es requerido",
      (v) =>
        /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\\-]*[a-zA-Z0-9])\.){2,}$/.test(
          v
        ) || "Host no es valido",
    ],
    ipDnsRule: [
      (v) => !!v || "debe de ingresar una direccion IP",
      (v) =>
        /^((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])$/.test(
          v
        ) || "IP DNS no es valido",
    ],
    emailRules: [
      (v) => !!v || "Correo es obligatorio",
      (v) =>
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "Correo debe ser valido",
    ],
    cardContactos: [],
    cardDNS: [],
    cardContactoTipo: [],
    cardContactoTipoHeader: "",
    dnsList: [],
    contactosList: [],
    dnsListClone: [],
    ipErr: false,
    hostErr: false,
    crearDns: [],
    tempArray: [],
    isDisabled: true,
  }),
  methods: {
    isCaseOne: function(host, value) {
      //el host es el mismo dominio que se compro, en este caso la ip es obligatoria
      if (host.includes(value)) {
        this.isDisabled = false;
      }
      
    },
    defaultValueCardContactos: async function() {
      let def = this.contactosList.filter(
        (x) =>
          x.nombre.toLowerCase().includes("pago") ||
          x.nombre.toLowerCase().includes("cnico") ||
          x.nombre.toLowerCase().includes("administrativo")
      );
      def.forEach((x) => this.addContactoCard(x.nombre));
      for (var i = 0; i < def.length; i++) {
        for (let j = 0; j < this.contactosList.length; j++) {
          if (this.contactosList[j].nombre === def[i].nombre) {
            this.contactosList.splice(j, 1);
          }
        }
      }
    },
    toasterMessageResponse: function(msg, type) {
      Vue.$toast.open({
        message: msg,
        type: type,
      });
    },
    solicitar: async function() {
      this.overlay = true;
      //cuando esta eb estad suspendido y lo quiere pagar otra vez: la anaulidad(costo dominio) y la reactivacion (arancel no existe aun)
      let hosting = [];
      for (let i = 0; i < this.cardDNS.length; i++) {
        let ipsss = [];
        for (
          let j = 0;
          j < this.cardDNS[i].detalle[0].direccionesIP.length;
          j++
        ) {
          ipsss.push(this.cardDNS[i].detalle[0].direccionesIP[j].value);
        }
        hosting.push({ host: this.cardDNS[i].detalle[0].ip, ip: ipsss });
      }
      let contactos = [];
      for (let i = 0; i < this.cardContactos.length; i++) {
        contactos.push(this.cardContactos[i].contactos[0]);
      }
      let send = {
        idDetallePedido: this.param,
        contactos: contactos,
        dns: {
          crearDns: hosting,
        },
        autoRenovacion: true,
      };
      let token = localStorage.getItem(atob("usr"));
      this.$http.defaults.headers.common.Authorization = "Bearer " + token;
      await this.$http
        .post(this.$keys("SOLICITAR_DOMINIO"), send)
        .then((response) => {
          if (response.status == 200) {
            this.toasterMessageResponse(response.data.mensaje, "success");
          }
          this.overlay = true;
          this.$router.push("/panel/dominios");
        })
        .catch((error) => {
          this.msgerror = "";

          if (error.response.data?.errors) {
            for (var key in error.response.data.errors) {
              this.msgerror = this.msgerror + "<br>" + key;
              var value = error.response.data.errors[key];
             
              value.forEach((msg) => {
                if (this.msgerror) {
                  this.msgerror = this.msgerror + "<br>" + msg;
                } else {
                  this.msgerror = msg;
                }
              });
            }
          }

          if (error.response.data?.mensaje) {
            let erroresback = JSON.parse(error.response.data?.mensaje);
            for (var key2 in erroresback.errors) {
              var value2 = erroresback.errors[key2];

              value2.forEach((msg) => {
                if (this.msgerror) {
                  this.msgerror = this.msgerror + "<br>" + msg;
                } else {
                  this.msgerror = msg;
                }
              });
            }
          }

          if (error.response.data?.mensaje) {
            let erroresback2 = JSON.parse(error.response.data?.mensaje);

            if (this.msgerror) {
              this.msgerror = this.msgerror + "<br>" + erroresback2?.mensaje;
            } else {
              this.msgerror = erroresback2?.mensaje;
            }
          }
          this.overlay = true;
        });
    },
    add(arreglo, index, mail) {
      arreglo.correoElectronico.push({
        value: mail,
      });
    },
    pushDireccionIP(arreglo) {
      arreglo.direccionesIP.push({
        value: "",
      });
    },
    addDireccionIP(arreglo, index, value) {
      let valid = this.isValidIP(value);
      let ip = [];
      if (valid) {
        let index = this.crearDns.findIndex((x) => x.host === arreglo.ip);
        if (index < 0) {
          ip.push(value);
          this.crearDns.push({
            host: arreglo.ip,
            ip,
          });
        } else {
          ip.push(value);
          this.crearDns[index].ip.splice(index, 0, value);
        }
      }
    },
    removeIP(arreglo, index) {
      arreglo.direccionesIP.splice(index, 1);
    },
    remove(arreglo, index) {
      arreglo.correoElectronico.splice(index, 1);
    },
    getContactoTipo: async function() {
      var token = localStorage.getItem(atob("usr"));
      this.$http.defaults.headers.common.Authorization = "Bearer " + token;
      await this.$http
        .get(this.$keys("CONTACTO_TIPO"))
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
            this.contactosList = response.data;
          }
        })

        .catch();
    },
    isValidIP: function(value) {
      return this.ipRegx.test(value);
    },
    addContactoCard: function(value) {
      let index = this.contactosList.findIndex((x) => x.nombre === value);
      let id = this.contactosList[index].idContactoTipo;
      this.cardContactos.push({
        name: value,
        contactos: [
          {
            idTipoContacto: id,
            nombre: "",
            telefono: "",
            celular: "",
            correoElectronico: [
              {
                value: "",
              },
            ],
            recibeNotificacion: false,
          },
        ],
      });
    },
    removeContactoCard: function(value) {
      const index = this.cardContactos.findIndex((x) => x.name === value);
      this.cardContactos.splice(index, 1);
    },
    removeDNSCard: function(value) {
      const index = this.cardDNS.findIndex((x) => x.id === value);
      this.cardDNS.splice(index, 1);
    },
    addDNSCard: function() {
      this.cardDNS.push({
        title: "Configuración DNS",
        id: this.cardDNS.length + 1,
        detalle: [
          {
            ip: "",
            direccionesIP: [
              {
                value: "",
              },
            ],
          },
        ],
      });
    },
  },
  watch: {
    overlay(val) {
      val &&
        setTimeout(() => {
          this.overlay = false;
        }, 1500);
    },
  },
};
</script>
<style>
.fontD {
  color: #616161 !important;
  font-weight: 600;
  font-size: 36px !important;
}
@media (max-width: 725px) {
  .fontD {
    font-size: 22px !important;
  }
}
.botonNegro {
  color: #616161;
  text-decoration: none;
}

.v-card {
  margin-bottom: 1rem;
}

.theme--light.v-label {
  color: #616161 !important;
}

.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active),
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon,
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-btn,
.theme--light.v-tabs > .v-tabs-bar .v-tab--disabled {
  color: #fff !important;
}

.ph {
  margin-top: -20px !important;
}
</style>
